import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import NetworkToggle from '../NetworkToggle/NetworkToggle'
import StatisticBox from '../StatisticBox/StatisticBox'
import StatisticMobileBox from '../StatisticMobileBox/StatisticMobileBox'
import { useRouter } from 'next/router'

export default function StatisticContainer({ globalStatistics }: any) {
  const [isMobile, setIsMobile] = useState(false)
  const router = useRouter()
  const route = router.route
  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    }
  }, [])

  return (
    <>
      {isMobile ? (
        <Box display="flex" flexDirection={'column'} gap="1.5rem">
          <NetworkToggle />
          {globalStatistics && (
            <StatisticMobileBox
              title1={'Total alerts sent'}
              value1={globalStatistics?.totalAlerts?.toString()}
              title2={'Alerts today'}
              value2={globalStatistics?.totalAlertsToday?.toString()}
              title3={route == '/' ? 'Avg. response time ' : undefined}
              value3={
                route == '/'
                  ? globalStatistics?.avgResponse && !isNaN(globalStatistics?.avgResponse)
                    ? globalStatistics?.avgResponse?.toString() + ' ms'
                    : '- ms'
                  : undefined
              }
            />
          )}
        </Box>
      ) : (
        <Box display="flex" gap="24px">
          <NetworkToggle />
          <Box display="flex" width="100%" gap="24px">
            <StatisticBox
              title={'Total alerts sent'}
              value={globalStatistics?.totalAlerts?.toString()}
            />
            <StatisticBox
              title={'Alerts today'}
              value={globalStatistics?.totalAlertsToday?.toString()}
            />
            {/* {globalStatistics.subscriberCount && (
              <StatisticBox title={'Subscribers'} value={globalStatistics?.subscriberCount + 8} />
            )} */}
            {globalStatistics.avgResponse ? (
              <StatisticBox
                title={'Avg. response time'}
                value={
                  globalStatistics?.avgResponse
                    ? globalStatistics?.avgResponse?.toString() + ' ms'
                    : '- ms'
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
