import { Box, styled, Typography } from '@mui/material'
import React from 'react'

export default function StatisticMobileBox({
  title1,
  value1,
  title2,
  value2,
  title3,
  value3,
}: {
  title1: string
  value1: string
  title2: string
  value2: string
  title3?: string
  value3?: string
}) {
  return (
    <Container>
      <Box display="flex" width="100%" justifyContent={'space-between'} gap="20px">
        <Typography
          sx={{
            width: '50%',
            fontSize: '16px',
            color: 'var(--white-48, rgba(255, 255, 255, 0.48))',
          }}
        >
          {title1}
        </Typography>
        <Typography
          sx={{
            width: '50%',
            fontSize: '16px',
            color: 'white',
          }}
        >
          {value1}
        </Typography>
      </Box>
      <Box display="flex" width="100%" justifyContent={'space-between'} gap="20px">
        <Typography
          sx={{
            width: '50%',
            fontSize: '16px',
            color: 'var(--white-48, rgba(255, 255, 255, 0.48))',
          }}
        >
          {title2}
        </Typography>
        <Typography
          sx={{
            width: '50%',
            fontSize: '16px',
            color: 'white',
          }}
        >
          {value2}
        </Typography>
      </Box>
      {title3 && value3 && (
        <Box display="flex" width="100%" justifyContent={'space-between'} gap="20px">
          <Typography
            sx={{
              width: '50%',
              fontSize: '16px',
              color: 'var(--white-48, rgba(255, 255, 255, 0.48))',
            }}
          >
            {title3}
          </Typography>
          <Typography
            sx={{
              width: '50%',
              fontSize: '16px',
              color: 'white',
            }}
          >
            {value3}
          </Typography>
        </Box>
      )}
    </Container>
  )
}

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  height: '100%',
  padding: '20px',
  background: 'rgba(255, 255, 255, 0.12)',
  borderRadius: '12px',
  '@media (max-width: 600px)': {
    padding: '20px 16px',
  },
}))
