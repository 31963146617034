import { ProjectChainData } from '@/data/ProjectChainData'
import { styled } from '@mui/material/styles'

export const Layout = styled('div')<{ background?: 'transparent' }>(({ background }) => ({
  position: 'relative',
  backgroundColor: ProjectChainData.BACKGROUND_COLOR,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: '100vh',
  fontFamily: ProjectChainData.CHAIN_FONT,
}))
