
import { pxToRem } from "@/common/utils";
import { Box, styled } from "@mui/material";

export const PageContainerLayout = styled(Box)(({theme}) => ({
  maxWidth: pxToRem(940),
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: pxToRem(20),
  padding: '20px 0px',
  zIndex: 5,
  [theme.breakpoints.down('md')]: {
    padding: pxToRem(30),
  },
    [theme.breakpoints.down('sm')]: {
    padding: pxToRem(22),
    },
}))
