import { ProjectChainData } from '@/data/ProjectChainData'
import { ReactComponent as Plus } from '@/assets/icons/Plus.svg'
import Link from 'next/link'
import { StyledButton } from '../StyledButton/StyledButton'
import { Container, Logo, NavbarStyledButton } from './Navbar.styles'
import { useRouter } from 'next/router'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

interface Props {
  showNewAlertBtn?: boolean
}

export default function Navbar({ showNewAlertBtn = false }: Props) {
  const router = useRouter()
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    }
  }, [])
  return (
    <Container>
      <Link href={'/'} style={{ display: 'flex' }}>
        {isMobile ? (
          ProjectChainData.CHAIN_LOGO_MOBILE
        ) : (
          <Logo> {ProjectChainData.CHAIN_LOGO}</Logo>
        )}
      </Link>
      <Box display="flex" gap="12px">
        {/* <NavbarStyledButton
          sx={{
            '&:hover': {
              transition: 'all 0.2s ease-in-out',
              backgroundColor: '#FFFFFF3D',
              border: '1px solid #FFFFFF3D',
            },
          }}
          className={isMobile ? 'isMobile': ''}
        >
          <Link href={'/top'}>
            Top alerts
          </Link>
        </NavbarStyledButton> */}
        <NavbarStyledButton
          sx={{
            '&:hover': {
              transition: 'all 0.2s ease-in-out',
              backgroundColor: '#FFFFFF3D',
              border: '1px solid #FFFFFF3D',
            },
          }}
          className={isMobile ? 'isMobile' : ''}
        >
          <Link
            target="blank"
            href={'https://discord.com/channels/1052434442653810769/1105128685893320704'}
          >
            Feedback
          </Link>
        </NavbarStyledButton>
        {showNewAlertBtn && !isMobile && (
          <StyledButton onClick={() => router.push('/create')}>
            <Plus />
            New Alert
          </StyledButton>
        )}
      </Box>
    </Container>
  )
}
