import { ProjectChainData } from '@/data/ProjectChainData'
import { Box, Button } from '@mui/material'
import { useRouter } from 'next/router'
import React from 'react'

export default function NetworkToggle() {
  const router = useRouter()
  const { pathname } = router
  const redirectLink = (chainLink: string) => {
    if (pathname === '/create') {
      window.location.href = `${chainLink}/create`
    } else {
      window.location.href = `${chainLink}`
    }
  }
  return (
    <Box
      display="flex"
      sx={{
        backgroundColor: '#FFFFFF1F',
        borderRadius: '12px',
        alignItems: 'center',
        padding: '8px',
        gap: '8px',
      }}
    >
      {ProjectChainData.CHAIN_NETWORKS?.map((chain, index) => {
        return (
          <Button
            sx={{
              minWidth: '90px',
              display: 'flex',
              flexDirection: 'column',
              padding: '8px 16px',
              fontSize: '16px',
              height: '100%',
              backgroundColor:
                process.env.NETWORK_INDEX === index.toString() ? '#FFFFFF' : 'transparent',
              color: process.env.NETWORK_INDEX === index.toString() ? '#000000' : '#FFFFFF7A',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor:
                  process.env.NETWORK_INDEX === index.toString() ? '#FFFFFF' : '#FFFFFF1F',
              },
              '&:disabled': {
                color: process.env.NETWORK_INDEX === index.toString() ? '#000000' : '#FFFFFF7A',
              },
            }}
            key={chain.NETWORK.NETWORK_NAME}
            onClick={() => redirectLink(chain.NETWORK.NETWORK_ENDPOINT)}
            disabled={
              process.env.CHAIN_NAME === 'arbitrum' ||
              process.env.NETWORK_INDEX === index.toString()
            }
          >
            {process.env.NETWORK_INDEX === index.toString()
              ? chain.NETWORK?.NETWORK_LOGO
              : chain.NETWORK?.NETWORK_LOGO_DISABLED}{' '}
            {chain.NETWORK.NETWORK_NAME}
          </Button>
        )
      })}
    </Box>
  )
}
