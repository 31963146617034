import { Box, styled, Typography } from '@mui/material';

export default function StatisticBox({ title, value }: { title: string; value: string }) {
  return (
    <Container>
      <Typography
        sx={{
          fontSize: '16px',
          color: 'var(--white-48, rgba(255, 255, 255, 0.48))',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '20px',
          color: 'white',
        }}
      >
        {value}
      </Typography>
    </Container>
  )
}

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: '16px 24px',
  backgroundColor: '#FFFFFF1F',
  borderRadius: '12px',
  '@media (max-width: 600px)': {
    padding: '16px',
  },
}))
