import Navbar from '@/common/components/Navbar'
import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { Layout } from './styles/Layout.styles'
import { PageContainerLayout } from './styles/PageContainer.styles'

interface Props {
  children: ReactNode
  gradient?: ReactNode
  showNewAlertBtn?: boolean
}

export default function PageLayout({ children, gradient, showNewAlertBtn }: Props) {
  return (
    <Layout>
      {gradient}
      <PageContainerLayout>
        <Navbar showNewAlertBtn={showNewAlertBtn} />
        <Box
          m={'80px 0'}
          width={1}
          sx={{
            '@media (max-width: 600px)': {
              margin: '40px 0 0',
            },
          }}
        >
          {children}
        </Box>
      </PageContainerLayout>
    </Layout>
  )
}
