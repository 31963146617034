import { ProjectChainData } from '@/data/ProjectChainData'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

export const StyledButton = styled(Button)(() => ({
  width: 'fit-content',
  backgroundColor: ProjectChainData.BUTTON_COLOR_WL,
  color: 'white',
  padding: '8px 12px',
  borderRadius: '8px',
  gap: '10px',
  fontSize: '16px',
  '&:disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.24)',
  },
  '&:hover': {
    backgroundColor: ProjectChainData.BUTTON_HOVER_COLOR_WL,
  },
  '&.isMobile': {
    fontSize: "16px",
    border: 'none',
    background: 'transparent',
    backgroundColor: 'transparent',
  },
  '&.isMobileFull': {
    width: '100%',
  }
}))
